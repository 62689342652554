import { MftServiceClientOptions } from './types/mftServiceClient';
import {
  CheckRequest,
  DownloadChunkRequest,
  DownloadCompletedRequest,
  DownloadRequest,
  FileBasicPropertiesRequest,
  FileExistRequest,
  GetUserItemsByShareRequest,
  ItemCheckSumRequest,
  StorageOperationsRequest,
  UploadChunkRequest,
  UploadCompletedRequest,
  UploadRequestRequest,
  StorageEntityOperations,
  UploadChunkType,
} from './types/mftServiceBackend';

export class MftServiceClientOptionsValidator {
  public validate(params: Readonly<MftServiceClientOptions>): void {
    if (!params.uniqueDeviceId) {
      throw new Error('No DeviceId specified');
    }

    if (
      typeof params.pollingSleepTime !== 'number' ||
      params.pollingSleepTime <= 0
    ) {
      throw new Error('Polling sleep time needs to be larger than 0');
    }

    if (
      typeof params.pollingRetryMaximumDuration !== 'number' ||
      params.pollingRetryMaximumDuration <= 0
    ) {
      throw new Error(
        'Polling retry maximum duration needs to be larger than 0'
      );
    }

    if (typeof params.bufferSize !== 'number' || params.bufferSize <= 0) {
      throw new Error('Buffer size needs to be larger than 0');
    }

    if (
      typeof params.maximumFileSize !== 'number' ||
      params.maximumFileSize <= 0
    ) {
      throw new Error('The maximum file size needs to be larger than 0');
    }
  }

  public validateUserSecrets(params: Readonly<MftServiceClientOptions>): void {
    if (!params.userSecrets.token) {
      throw new Error('No oAuth token specified');
    }

    if (params.userSecrets.tokenExpiry < Date.now()) {
      throw new Error('oAuth token expired');
    }

    if (!params.userSecrets.signature) {
      throw new Error('No signature specified');
    }

    if (params.userSecrets.signatureExpiry < Date.now()) {
      throw new Error('Signature expired');
    }
  }

  public validateCheckRequest(request: Readonly<CheckRequest>): void {
    if (!request.filesPath || !request.filesPath.length) {
      throw new Error('No FilesPath specified');
    }
  }

  public validateFileExistRequest(request: Readonly<FileExistRequest>): void {
    if (!request.filePath) {
      throw new Error('No FilePath specified');
    }
  }

  public validateFileBasicPropertiesRequest(
    request: Readonly<FileBasicPropertiesRequest>
  ): void {
    if (!request.filePath) {
      throw new Error('No FilePath specified');
    }
  }

  public validateDownloadRequest(request: Readonly<DownloadRequest>): void {
    if (!request.filePath) {
      throw new Error('No FilePath specified');
    }
  }

  public validateDownloadChunkRequest(
    request: Readonly<DownloadChunkRequest>
  ): void {
    if (!request.downloadRequestId) {
      throw new Error('No DownloadRequestId specified');
    }

    if (typeof request.chunkNumber !== 'number' || request.chunkNumber < 0) {
      throw new Error('Chunk number needs to be 0 or larger');
    }
  }

  public validateDownloadCompletedRequest(
    request: Readonly<DownloadCompletedRequest>
  ): void {
    if (!request.downloadRequestId) {
      throw new Error('No DownloadRequestId specified');
    }
  }

  public validateItemCheckSumRequest(
    request: Readonly<ItemCheckSumRequest>
  ): void {
    if (!request.filePath) {
      throw new Error('No FilePath specified');
    }

    if (request.hashAlgorithm.toUpperCase() !== 'SHA256') {
      throw new Error('Only SHA256 hashing is supported');
    }
  }

  public validateUploadRequest(request: Readonly<UploadRequestRequest>): void {
    if (!request.fileName) {
      throw new Error('No FileName specified');
    }

    if (!request.filePath) {
      throw new Error('No FilePath specified');
    }

    if (!request.checkSum) {
      throw new Error('No CheckSum specified');
    }

    if (typeof request.chunkNumber !== 'number' || request.chunkNumber < 0) {
      throw new Error('Chunk number needs to be 0 or larger');
    }
  }

  public validateUploadChunk(request: Readonly<UploadChunkRequest>): void {
    if (!request.uploadRequestId) {
      throw new Error('No UploadRequestId specified');
    }

    if (request.chunkType === UploadChunkType.byteArray && !request.byteArray) {
      throw new Error('No ByteArray specified');
    }

    if (request.chunkType === UploadChunkType.blob && !request.blob) {
      throw new Error('No Blob specified');
    }

    if (typeof request.chunkNumber !== 'number' || request.chunkNumber < 0) {
      throw new Error('Chunk number needs to be 0 or larger');
    }
  }

  public validateUploadCompleted(
    request: Readonly<UploadCompletedRequest>
  ): void {
    if (!request.uploadRequestId) {
      throw new Error('No UploadRequestId specified');
    }
  }

  public validateGetUserItemsByShare(
    request: Readonly<GetUserItemsByShareRequest>
  ): void {
    if (!request.path) {
      throw new Error('No Path specified');
    }
  }

  public validateStorageOperations(
    request: Readonly<StorageOperationsRequest>
  ): void {
    if (
      Object.values(StorageEntityOperations).indexOf(
        request.storageEntityOperations
      ) === -1
    ) {
      throw new Error(
        `An invalid value ${request.storageEntityOperations} specified as request.StorageEntityOperations`
      );
    }

    if (!request.action1) {
      throw new Error('No Action1 specified');
    }
  }
}

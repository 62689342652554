import { MftServiceClientOptionsValidator } from './MftServiceClientOptionsValidator';
import { MftServiceClientOptions } from './types/mftServiceClient';
import {
  DownloadChunkRequest,
  DownloadChunkResult,
  DownloadCompletedRequest,
  DownloadCompletedResult,
  DownloadRequest,
  DownloadResult,
  ItemCheckSumRequest,
  ItemCheckSumResult,
} from './types/mftServiceBackend';
import { decryptChunk } from './utils/encryption';
import { defaultOptions } from './utils/defaultOptions';
import { fetchDataWithRetry } from './utils/fetchDataWithRetry';
import { getBaseRequest } from './utils/getBaseRequest';
import { getRequestHeaders } from './utils/getRequestHeaders';
import { refreshUserSecretsIfExpired } from './utils/userSecrets';

export class Download {
  private readonly validator = new MftServiceClientOptionsValidator();

  constructor(private options: Partial<Readonly<MftServiceClientOptions>>) {}

  /**
   *
   * @param request The `filePath` to start downloading with the `bufferSize`.
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async downloadRequest(
    request: Readonly<DownloadRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<DownloadResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateDownloadRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
      bufferSize: params.bufferSize,
    };

    const result = await fetchDataWithRetry<DownloadResult>(
      `${params.origin}/api/download/v1/DownloadRequest`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onDownloadRequestDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  /**
   *
   * @param request The `downloadRequestId` and `chunkNumber` to download.
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async downloadChunk(
    request: Readonly<DownloadChunkRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<DownloadChunkResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateDownloadChunkRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<DownloadChunkResult>(
      `${params.origin}/api/download/v1/DownloadChunk`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    // Decode Base 64 encoded response string
    result.FileContent = decryptChunk(atob(result.FileContent));

    try {
      params.onDownloadChunkDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  /**
   *
   * @param request The `downloadRequestId` to complete.
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async downloadCompleted(
    request: Readonly<DownloadCompletedRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<DownloadCompletedResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateDownloadCompletedRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<DownloadCompletedResult>(
      `${params.origin}/api/download/v1/DownloadCompleted`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onDownloadCompletedDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  /**
   *
   * @param request The `downloadRequestId` to complete.
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async itemCheckSum(
    request: Readonly<ItemCheckSumRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<ItemCheckSumResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateItemCheckSumRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<ItemCheckSumResult>(
      `${params.origin}/api/download/v1/ItemCheckSum`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onItemCheckSumDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }
}

import localforage from 'localforage';

import { CollaboardObjectToUpload, ErrorMessages } from './types';

export class ResumableUploadsStore {
  private store?: LocalForage;

  public async init(): Promise<void> {
    const tmpStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'collaboard-blob-resource',
      storeName: 'pending-uploads',
    });

    try {
      await tmpStore.ready();
      this.store = tmpStore;
    } catch (e) {
      throw new Error(ErrorMessages.NO_RESUMABLE_UPLOADS_STORE);
    }
  }

  public async persistFile(
    object: CollaboardObjectToUpload,
    file: File
  ): Promise<void> {
    await void this.store?.setItem<File>(object.uuid, file);
  }

  public async getFile(object: CollaboardObjectToUpload): Promise<File | null> {
    return this.store?.getItem<File>(object.uuid) || null;
  }

  public async removeFile(object: CollaboardObjectToUpload): Promise<void> {
    return this.store?.removeItem(object.uuid);
  }
}

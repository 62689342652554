import { MftServiceClientOptionsValidator } from './MftServiceClientOptionsValidator';
import { defaultOptions } from './utils/defaultOptions';
import { fetchDataWithRetry } from './utils/fetchDataWithRetry';
import { getBaseRequest } from './utils/getBaseRequest';
import { getRequestHeaders } from './utils/getRequestHeaders';
import { refreshUserSecretsIfExpired } from './utils/userSecrets';
import { MftServiceClientOptions } from './types/mftServiceClient';
import {
  GetUserItemsByShareRequest,
  GetUserItemsByShareResult,
} from './types/mftServiceBackend';

export class Users {
  private readonly validator = new MftServiceClientOptionsValidator();

  constructor(private options: Partial<Readonly<MftServiceClientOptions>>) {}

  /**
   *
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  public async getUserItemsByShare(
    request: Readonly<GetUserItemsByShareRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<GetUserItemsByShareResult> {
    const initialParams: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateGetUserItemsByShare(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<GetUserItemsByShareResult>(
      `${params.origin}/api/users/v1/GetUserItemsByShare`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    result.FilesTree.forEach(item => {
      item.ServerLastModified = new Date(item.ServerLastModified);
    });

    try {
      params.onGetUserItemsByShareDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }
}

import {
  CorrelationId,
  MftServiceClient,
  GetUserItemsByShareResult,
  DocumentInfo,
  MftServiceClientOptions,
} from 'mft-service-client';

import { assertNotUndefined } from '../assertions';
import { exponentialRetry } from '../promises';
import { StorageClient } from '../StorageClient';
import {
  OnProgressCallback,
  RequestAborted,
  StorageClientConfig,
  CollaboardObject,
  CollaboardObjectToRestore,
  ResumedObject,
  CollaboardObjectToUpload,
} from '../types';

type MFTResult = {
  ErrorCode: number;
};

export class MFTStorageClient extends StorageClient {
  private client: MftServiceClient;
  private abortController = new AbortController();

  private CORRELATION_ID_SPLIT = '|:|:|';

  private userItems?: GetUserItemsByShareResult;
  private _awaitedItems: string[] = [];
  private _awaitedItemsPromise?: Promise<void>;
  private _awaitedItemsCallbacks: Array<() => boolean> = [];

  constructor(config: StorageClientConfig) {
    super(config);

    const mftOptions = assertNotUndefined(config.mft, 'mft');

    const {
      origin,
      deviceId,
      appVer,
      // optional
      maximumFileSize,
      pollingRetryMaximumDuration,
      uploadChunkAsByteArray,
    } = mftOptions;

    const mftConfig: Partial<MftServiceClientOptions> = {
      appVer: assertNotUndefined(appVer, 'appVer'),
      origin: assertNotUndefined(origin, 'origin'),
      uniqueDeviceId: assertNotUndefined(deviceId, 'deviceId'),
      useStorage: this.enableResumableUploads,
      uploadChunkAsByteArray: !!uploadChunkAsByteArray,
    };

    // Only set optional options if they are defined otherwise `undefined` will be used
    if (pollingRetryMaximumDuration) {
      mftConfig.pollingRetryMaximumDuration = pollingRetryMaximumDuration;
    }

    if (maximumFileSize) {
      mftConfig.maximumFileSize = maximumFileSize;
    }

    this.client = new MftServiceClient(mftConfig);
  }

  public async start(): Promise<void> {
    super.start();

    this.abortController.abort();
    this.abortController = new AbortController();
  }

  public async destroy(): Promise<void> {
    super.destroy();
    this.abortController.abort();
  }

  /**
   * Get a signed URL for accessing the file with something like an `<img>`
   */
  public async getUrl(object: CollaboardObject): Promise<string> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    const containerUri = assertNotUndefined(this.containerUri, 'containerUri');

    const getCredentials = () => this.getStorageAccessCredentials(projectId);
    const credentials = await getCredentials();

    if (object.fileExtension) {
      const extension = this.addDotToExtension(object.fileExtension);
      const fileName = `${containerUri}${object.uuid}${extension}`;
      // If we already have the file extension then we don't need to load the list
      return this.client.getResourceURL(
        {
          fileName,
        },
        {
          abortController: this.abortController,
          userSecrets: credentials,
          getUserSecrets: getCredentials,
        }
      );
    }

    /**
     * LEGACY MODE - perform expensive lookup to find file extension
     */
    await this.waitForUserItemLoaded(projectId, object);

    const ext = this.getFileExtension(object.uuid);
    const fileName = `${containerUri}${object.uuid}${ext}`;

    return this.client.getResourceURL(
      {
        fileName,
      },
      {
        abortController: this.abortController,
        userSecrets: credentials,
        getUserSecrets: getCredentials,
      }
    );
  }

  /**
   * Download a file (chunked)
   *
   * @override
   */
  public async downloadAsBlob(
    object: CollaboardObject,
    onProgress?: OnProgressCallback
  ): Promise<Blob> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    const containerUri = assertNotUndefined(this.containerUri, 'containerUri');

    const getCredentials = () => this.getStorageAccessCredentials(projectId);
    const credentials = await getCredentials();

    let totalChunks: number;
    let chunksFinished = 0;

    const options: Partial<MftServiceClientOptions> = {
      abortController: this.abortController,
      bufferSize: this.downloadChunkSize,
      userSecrets: credentials,
      getUserSecrets: getCredentials,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onFileBasicPropertiesDone: (_, { Size }: any) => {
        if (Size) {
          totalChunks = Math.ceil(Size / this.downloadChunkSize);
        }
      },
      onDownloadChunkDone: () => {
        chunksFinished += 1;
        if (onProgress) {
          onProgress(Math.min(chunksFinished / totalChunks, 1));
        }
      },
      onDownloadCompletedDone: () => {
        if (onProgress) {
          onProgress(1);
        }
      },
    };

    if (object.fileExtension) {
      const ext = this.addDotToExtension(object.fileExtension);
      const filePath = `${containerUri}${object.uuid}${ext}`;

      return this.client.downloadToBlob(filePath, options);
    }

    /**
     * LEGACY MODE - perform expensive lookup to find file extension
     */
    await this.waitForUserItemLoaded(projectId, object);

    const ext = this.getFileExtension(object.uuid);
    const fileName = `${object.uuid}${ext}`;
    const filePath = `${containerUri}${fileName}`;

    return this.client.downloadToBlob(filePath, options);
  }

  /**
   * Upload a file
   */
  public async upload(
    object: CollaboardObjectToUpload,
    file: File,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    const containerUri = assertNotUndefined(this.containerUri, 'containerUri');

    let totalChunks = 1;
    let chunksFinished = 0;

    const correlationId = this.createCorrelationId(object);
    const objectName = this.getObjectNameFromObject(object);
    const renamedFileToUpload = new File([file], objectName);

    const getCredentials = () => this.getStorageAccessCredentials(projectId);
    const credentials = await getCredentials();

    await this.client
      .uploadFile(renamedFileToUpload, containerUri, {
        abortController: this.abortController,
        bufferSize: this.uploadChunkSize,
        correlationId,
        userSecrets: credentials,
        getUserSecrets: getCredentials,
        onUploadChunkDone: () => {
          chunksFinished += 1;
          if (onProgress) {
            onProgress(Math.min(chunksFinished / totalChunks, 1));
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onUploadRequestDone: ({ chunkNumber }: any) => {
          // Actually UploadedChunk
          totalChunks = chunkNumber;
        },
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          throw new RequestAborted();
        }
        throw err;
      });
  }

  /**
   * Resume all pending uploads
   */
  protected async resumePendingUploads(): Promise<void> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    const getCredentials = () => this.getStorageAccessCredentials(projectId);
    const credentials = await getCredentials();

    return this.client.resumePendingUploads({
      abortController: this.abortController,
      bufferSize: this.uploadChunkSize,
      userSecrets: credentials,
      getUserSecrets: getCredentials,
      onWaitForMergeReadyDone: (_, result, correlationId) => {
        const mftResult = result as MFTResult;
        if (mftResult.ErrorCode === 0) {
          const object = this.parseCorrelationId(correlationId);
          this.onResumeUploadComplete && this.onResumeUploadComplete(object);
        }
      },
      onUploadChunkDone: (_, result, correlationId) => {
        const mftResult = result as MFTResult;
        if (mftResult.ErrorCode === 0) {
          const object = this.parseCorrelationId(correlationId);
          this.onResumeUploadChunk && this.onResumeUploadChunk(object);
        }
      },
    });
  }

  /**
   * Restore a file (AKA copy previous object to a new name)
   */
  public async restore(
    object: CollaboardObjectToRestore,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    const containerUri = assertNotUndefined(this.containerUri, 'containerUri');

    const uuid = assertNotUndefined(object.uuid, 'uuid');
    const previousUuid = assertNotUndefined(
      object.previousUuid,
      'previousUuid'
    );

    onProgress && onProgress(0);

    const getCredentials = () => this.getStorageAccessCredentials(projectId);
    const credentials = await getCredentials();

    let source: string;
    let target: string;

    if (object.fileExtension) {
      const extension = this.addDotToExtension(object.fileExtension);
      source = `${containerUri}${previousUuid}${extension}`;
      target = `${containerUri}${uuid}${extension}`;
    } else {
      /**
       * LEGACY MODE - perform expensive lookup to find extension
       */
      await this.waitForUserItemLoaded(projectId, { uuid: previousUuid });
      const ext = this.getFileExtension(previousUuid);

      source = `${containerUri}${previousUuid}${ext}`;
      target = `${containerUri}${uuid}${ext}`;
    }

    await this.client
      .copyFile(source, target, {
        abortController: this.abortController,
        userSecrets: credentials,
        getUserSecrets: getCredentials,
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          throw new RequestAborted();
        }
        throw err;
      });

    onProgress && onProgress(1);
  }

  private async getUserItems(projectId: string | number): Promise<void> {
    const containerUri = assertNotUndefined(this.containerUri, 'containerUri');
    const getCredentials = () => this.getStorageAccessCredentials(projectId);
    const credentials = await getCredentials();

    this.userItems = await this.client.users
      .getUserItemsByShare(
        {
          path: containerUri,
        },
        {
          abortController: this.abortController,
          userSecrets: credentials,
          getUserSecrets: getCredentials,
        }
      )
      .catch(err => {
        if (err.name === 'AbortError') {
          throw new RequestAborted();
        }
        throw err;
      });
  }

  private getFileExtension = (uuid = ''): string =>
    this.userItems?.FilesTree?.find(f => f.Name.includes(uuid))?.Extension ??
    '';

  private getFileByUuid = (uuid = ''): DocumentInfo | null =>
    this.userItems?.FilesTree?.find(f => f.Name.includes(uuid)) ?? null;

  private async waitForUserItemLoaded(
    projectId: string | number,
    {
      uuid,
    }: {
      uuid: string;
    }
  ): Promise<void> {
    // A file should be available on the server after an upload.
    // Make sure it's available in the userItems collection.
    // It can take a few seconds to show up so retry if not found
    // If not found after 15 seconds there is something fishy

    return new Promise(resolve =>
      this.listenToFileExistence(projectId, uuid, () => {
        const fileExists = !!this.getFileByUuid(uuid);
        fileExists && resolve();
        return fileExists;
      })
    );
  }

  /**
   * Checks for multiple files existence in a single "exponential thread"
   * This is to avoid the situation, where N calls were made to GetUserItemsPerShare, 1 per file
   * */
  private async listenToFileExistence(
    projectId: string | number,
    uuid: string,
    callback: () => boolean
  ): Promise<void> {
    const errorMessage = 'apiError.fileNotFound';

    if (!this._awaitedItems.includes(uuid)) {
      this._awaitedItems.push(uuid);
      this._awaitedItemsCallbacks.push(callback);
    }

    this._awaitedItemsPromise =
      this._awaitedItemsPromise ||
      exponentialRetry(
        async () => {
          const fileExistenceMap = this._awaitedItemsCallbacks.map(c => c());

          this._awaitedItems = this._awaitedItems.filter(
            (_, i) => !fileExistenceMap[i]
          );

          this._awaitedItemsCallbacks = this._awaitedItemsCallbacks.filter(
            (_, i) => !fileExistenceMap[i]
          );

          if (this._awaitedItems.length) {
            this.getUserItems(projectId);
            throw new Error(errorMessage);
          }
        },
        {
          shouldRetry: error => error.message === errorMessage,
        }
      ).finally(() => delete this._awaitedItemsPromise);

    await this._awaitedItemsPromise;
  }

  // TODO - can we reuse this for other clients?
  private createCorrelationId(object: CollaboardObjectToUpload): string {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    // Ensure the order is correct
    const payload: ResumedObject = {
      uuid: object.uuid,
      type: object.type,
      fileName: escape(object.fileName),
      projectId: String(projectId),
    };
    return Object.values(payload).join(this.CORRELATION_ID_SPLIT);
  }

  private parseCorrelationId(correlationId: CorrelationId): ResumedObject {
    const [uuid, type, fileName, projectId] = String(correlationId).split(
      this.CORRELATION_ID_SPLIT
    );

    return {
      uuid,
      type,
      fileName: unescape(fileName),
      projectId,
    };
  }
}

import { AbortController } from '@azure/abort-controller';
import {
  ContainerClient,
  BlockBlobClient,
  BlobBeginCopyFromUrlPollState,
} from '@azure/storage-blob';

import { StorageClient } from '../StorageClient';
import { chunkFile } from '../files';
import {
  CollaboardObject,
  ErrorMessages,
  FileChunk,
  OnProgressCallback,
  RequestAborted,
  UploadedChunk,
  CollaboardObjectToRestore,
  CollaboardObjectToUpload,
} from '../types';
import { assertNotUndefined } from '../assertions';

export class AzureStorageClient extends StorageClient {
  protected USE_PROJECT_ID_IN_KEY = true;

  protected MIN_CHUNK_SIZE = 1024 * 1024;

  protected uploadsWithoutFirstBlock = new Set();

  private abortController = new AbortController();

  public async start(): Promise<void> {
    super.start();

    this.abortController.abort();
    this.abortController = new AbortController();
  }

  public async destroy(): Promise<void> {
    super.destroy();

    this.abortController.abort();
  }

  /**
   * Get a signed URL for accessing the file with something like an `<img>`
   */
  public async getUrl(object: CollaboardObject): Promise<string> {
    const objectClient = await this.getObjectClient(
      object.uuid,
      object.fileExtension
    );
    return objectClient.url;
  }

  protected async resumePendingUploads(): Promise<void> {
    // TODO - implement this
    return;
  }

  /**
   * Upload a file
   */
  public async upload(
    object: CollaboardObjectToUpload,
    file: File,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    this.storePendingUpload(object, file);

    this.uploadsWithoutFirstBlock.add(object.uuid);

    const objectName = this.getObjectNameFromObject(object);
    const objectClient = await this.getObjectClient(
      object.uuid,
      object.fileExtension
    );

    const uncommitedBlocks = await this.getUncommitedBlocks(objectClient, {
      suppress404: true,
    });

    if (uncommitedBlocks.length) {
      this.uploadsWithoutFirstBlock.delete(object.uuid);
    }

    const chunks = chunkFile(file, this.uploadChunkSize);

    const totalChunks = chunks.length;
    let totalChunksUploaded = 0;

    // This loop approach will parallelize the requests
    const uploads = chunks.map<Promise<UploadedChunk>>(async fileChunk => {
      const { chunk, chunkNumber } = fileChunk;
      const chunkId = this.getChunkId(object, fileChunk);

      // Skip chunks that are already on Azure
      if (!uncommitedBlocks.includes(chunkId)) {
        try {
          await objectClient
            .stageBlock(chunkId, chunk, chunk.size, {
              abortSignal: this.abortController.signal,
            })
            .catch(err => {
              if (err.name === 'AbortError') {
                throw new RequestAborted();
              }
              throw err;
            });
          this.uploadsWithoutFirstBlock.delete(object.uuid);
        } catch (err) {
          console.error(err);
        }
      }

      totalChunksUploaded += 1;

      if (onProgress) {
        onProgress(Math.min(totalChunksUploaded / totalChunks, 1));
      }

      return {
        chunkId,
        chunkNumber,
      };
    });

    const uploadedChunks = await Promise.all(uploads);

    const chunkIds = uploadedChunks.map(chunk => {
      return chunk.chunkId;
    });

    await objectClient
      .commitBlockList(chunkIds, {
        abortSignal: this.abortController.signal,
        metadata: {
          fileName: escape(object.fileName),
          extension: escape(object.fileExtension),
        },
        blobHTTPHeaders: {
          blobContentDisposition: `attachment; filename="${objectName}"`,
          blobCacheControl: 'public, max-age=31536000',
          blobContentType: object.fileType,
        },
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          throw new RequestAborted();
        }
        throw err;
      });

    this.removePendingUpload(object);
  }

  /**
   * Restore a file (AKA copy previous object to a new name)
   */
  public async restore(
    object: CollaboardObjectToRestore,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    const previousUuid = assertNotUndefined(
      object.previousUuid,
      'previousUuid'
    );

    if (this.uploadsWithoutFirstBlock.has(object.uuid)) {
      throw new Error(ErrorMessages.UPLOAD_INCOMPLETE);
    }

    const existingObjectClient = await this.getObjectClient(
      previousUuid,
      object.fileExtension
    );
    const uncommitedBlocks = await this.getUncommitedBlocks(
      existingObjectClient
    );

    if (uncommitedBlocks.length) {
      throw new Error(ErrorMessages.UPLOAD_INCOMPLETE);
    }

    const url = existingObjectClient.url;

    const newObjectClient = await this.getObjectClient(
      object.uuid,
      object.fileExtension
    );

    await newObjectClient
      .beginCopyFromURL(url, {
        abortSignal: this.abortController.signal,
        onProgress: (state: BlobBeginCopyFromUrlPollState) => {
          console.log(state); // TODO - figure out how progress is reported here
          if (onProgress) {
            onProgress(1);
          }
        },
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          throw new RequestAborted();
        }
        throw err;
      });
  }

  /**
   * Resume an upload
   */
  public async resumeUpload(
    _object: CollaboardObject,
    _onProgress?: OnProgressCallback
  ): Promise<void> {
    // TODO
  }

  /**
   * Resume all pending uploads
   */
  public async resumeAllUploads(): Promise<void> {
    // TODO
  }

  private async getUncommitedBlocks(
    objectClient: BlockBlobClient,
    options?: {
      suppress404: boolean;
    }
  ) {
    const dummyBlockId = btoa(
      encodeURIComponent('00000000-0000-0000-0000-000000000000_0000000000')
    );

    if (options && options.suppress404) {
      // #3851 Make sure at least one Block has been uploaded.
      //       If not a 404 error shows up in the developer tools
      //       This block will never be committed so Azure will purge it automatically
      const block = new Blob(['a']);
      await objectClient
        .stageBlock(dummyBlockId, block, block.size, {
          abortSignal: this.abortController.signal,
        })
        .catch(err => {
          if (err.name === 'AbortError') {
            throw new RequestAborted();
          }
          throw err;
        });
    }

    const blockList = await objectClient
      .getBlockList('uncommitted', {
        abortSignal: this.abortController.signal,
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          throw new RequestAborted();
        }
        throw err;
      });

    return (blockList.uncommittedBlocks || [])
      .map(item => item.name)
      .filter(item => item !== dummyBlockId);
  }

  private getChunkId(object: CollaboardObject, fileChunk: FileChunk): string {
    return btoa(
      encodeURIComponent(
        `${object.uuid}_${fileChunk.chunkNumber.toString().padStart(10, '0')}`
      )
    );
  }

  private async getObjectClient(
    uuid: string,
    fileExtension: string
  ): Promise<BlockBlobClient> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');

    const { host, signature } = await this.getStorageAccessCredentials(
      projectId
    );
    const containerUri = `${host}${signature}`;

    const container = new ContainerClient(containerUri);

    return container.getBlockBlobClient(
      `${uuid}${fileExtension}`.toLowerCase()
    );
  }
}

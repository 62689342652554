import axios from 'axios';
import { Parser, Builder } from 'xml2js';
import { assertNotUndefined } from '../assertions';

import { chunkFile } from '../files';
import { StorageClient } from '../StorageClient';
import {
  CollaboardObjectToRestore,
  CollaboardObject,
  FileChunk,
  OnProgressCallback,
  RequestAborted,
  SignatureAction,
  UploadedChunk,
  StorageObject,
  CollaboardObjectToUpload,
} from '../types';
import { buildSignedUrl } from '../urls';

export class AWSStorageClient extends StorageClient {
  protected MIN_CHUNK_SIZE = 1024 * 1024 * 5;

  public async getUrl(object: CollaboardObject): Promise<string> {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    const storageObject = this.getStorageObjectFromObject(object);
    const objectKey = this.getObjectKey(storageObject.objectName);

    const credentials = await this.getStorageAccessCredentials(
      projectId,
      storageObject,
      SignatureAction.GetObject
    );

    return buildSignedUrl(credentials, objectKey);
  }

  protected async resumePendingUploads(): Promise<void> {
    // TODO - implement this
    return;
  }

  public upload(
    object: CollaboardObjectToUpload,
    file: File,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    assertNotUndefined(this.projectId, 'projectId');

    const { size } = file;

    if (size < this.uploadChunkSize) {
      return this.uploadAtomic(object, file, onProgress);
    }

    return this.uploadMultipart(object, file, onProgress);
  }

  /**
   * Restore a file (AKA copy previous object to a new name)
   */
  public async restore(
    object: CollaboardObjectToRestore
    // onProgress?: OnProgressCallback
  ): Promise<void> {
    // TODO = implement this
    console.log(object);
  }

  protected async uploadAtomic(
    object: CollaboardObjectToUpload,
    file: File,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    // Use the same project ID for all async actions in this operation
    const projectId = assertNotUndefined(this.projectId, 'projectId');

    this.storePendingUpload(object, file);

    const storageObject = this.getStorageObjectFromObject(object);
    const { objectName, fileType } = storageObject;
    const objectKey = this.getObjectKey(objectName);

    const credentials = await this.getStorageAccessCredentials(
      projectId,
      storageObject,
      SignatureAction.PutObject
    );

    const url = buildSignedUrl(credentials, objectKey);

    await axios
      .put(url, file, {
        cancelToken: this.cancelToken.token,
        headers: {
          // NOTE: The AWS signature includes the metadata, e.g. filename
          'content-type': fileType,
        },
        onUploadProgress: (event: ProgressEvent) => {
          if (onProgress) {
            onProgress(event.loaded / event.total);
          }
        },
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          throw new RequestAborted();
        }

        throw err;
      });

    this.removePendingUpload(object);
  }

  protected async uploadMultipart(
    object: CollaboardObjectToUpload,
    file: File,
    onProgress?: OnProgressCallback
  ): Promise<void> {
    // Use the same project ID for all async actions in this operation
    const projectId = assertNotUndefined(this.projectId, 'projectId');

    this.storePendingUpload(object, file);

    const storageObject = this.getStorageObjectFromObject(object);
    const { objectName } = storageObject;
    const objectKey = this.getObjectKey(objectName);

    const uploadId = await this.startMultipartUpload(
      projectId,
      storageObject,
      objectKey
    );

    const chunks = chunkFile(file, this.uploadChunkSize);

    const totalChunks = chunks.length;
    let totalChunksUploaded = 0;

    // This loop approach will parallelize the requests
    const uploads = chunks.map(async fileChunk => {
      const result = await this.uploadMultipartPart(
        projectId,
        storageObject,
        objectKey,
        uploadId,
        fileChunk
      );

      totalChunksUploaded += 1;

      if (onProgress) {
        onProgress(Math.min(totalChunksUploaded / totalChunks, 1));
      }

      return result;
    });

    const uploadedChunks = await Promise.all(uploads);

    await this.completeMultipartUpload(
      projectId,
      storageObject,
      objectKey,
      uploadId,
      uploadedChunks
    );

    this.removePendingUpload(object);
  }

  private async startMultipartUpload(
    projectId: string | number,
    storageObject: StorageObject,
    objectKey: string
  ): Promise<string> {
    const { fileType } = storageObject;

    const credentials = await this.getStorageAccessCredentials(
      projectId,
      storageObject,
      SignatureAction.StartMultipartUpload
    );

    const url = buildSignedUrl(credentials, objectKey, {
      uploads: '',
    });

    const { data } = await axios
      .post(url, undefined, {
        cancelToken: this.cancelToken.token,
        headers: {
          // NOTE: The AWS signature includes the metadata, e.g. filename
          'content-type': fileType,
        },
        responseType: 'text',
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          throw new RequestAborted();
        }

        throw err;
      });

    const parser = new Parser();

    const {
      InitiateMultipartUploadResult: { UploadId },
    } = await parser.parseStringPromise(data);

    return UploadId[0];
  }

  private async uploadMultipartPart(
    projectId: string | number,
    storageObject: StorageObject,
    objectKey: string,
    uploadId: string,
    fileChunk: FileChunk
  ): Promise<UploadedChunk> {
    const { chunk, chunkNumber } = fileChunk;

    const credentials = await this.getStorageAccessCredentials(
      projectId,
      storageObject,
      SignatureAction.UploadMultipartChunk,
      {
        uploadId,
        chunkNumber,
      }
    );

    const url = buildSignedUrl(
      credentials,
      objectKey,
      // When using CloudFront we need to make sure that the params are in the URL
      // because the signature will be generic
      this.SIGN_EVERY_REQUEST
        ? {}
        : {
            uploadId,
            partNumber: chunkNumber,
          }
    );

    const { headers } = await axios
      .put(url, chunk, {
        cancelToken: this.cancelToken.token,
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          throw new RequestAborted();
        }

        throw err;
      });

    // Ensure S3 is configured to expose ETag header
    return {
      chunkId: headers['etag'],
      chunkNumber,
    };
  }

  private async completeMultipartUpload(
    projectId: string | number,
    storageObject: StorageObject,
    objectKey: string,
    uploadId: string,
    uploadedChunks: UploadedChunk[]
  ): Promise<void> {
    const credentials = await this.getStorageAccessCredentials(
      projectId,
      storageObject,
      SignatureAction.CompleteMultipartUpload,
      {
        uploadId,
        uploadedChunks,
      }
    );

    const url = buildSignedUrl(credentials, objectKey, {
      uploadId,
    });

    const builder = new Builder({
      xmldec: {
        version: '1.0',
        encoding: 'UTF-8',
        standalone: false, // Important, otherwise request fails
      },
    });

    const payload = builder.buildObject({
      CompleteMultipartUpload: {
        Part: uploadedChunks.map(chunk => {
          return {
            ETag: chunk.chunkId,
            PartNumber: chunk.chunkNumber,
          };
        }),
      },
    });

    await axios
      .post(url, payload, {
        cancelToken: this.cancelToken.token,
        headers: {
          'content-type': 'application/xml',
        },
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          throw new RequestAborted();
        }

        throw err;
      });
  }

  /**
   * Get the object key.
   *
   * The key is the virtual path of the file within the storage bucket.
   */
  protected getObjectKey(objectName: string): string {
    const projectId = assertNotUndefined(this.projectId, 'projectId');
    if (this.USE_PROJECT_ID_IN_KEY) {
      return encodeURI(`${projectId}/${objectName}`);
    }

    return encodeURI(objectName);
  }
}

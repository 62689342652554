type CallResult = { ErrorCode: number };

export type BaseRequestPayload = {
  appVer: string;
  uniqueDeviceId: string;
};

export type ActivityModel = {
  Id: string;
  ChunkSize: number;
  Checksum: string;
};
export type ActivityResult = CallResult & {
  MergedOnServer: ActivityModel[];
  ChunkedOnServer: ActivityModel[];
  CompletedOperationsOnServer: ActivityModel[];
};

export type CheckRequest = { filesPath: string[] };
export type CheckResult = CallResult & {
  FilesInfo: { [path: string]: Date };
};

export type FileExistRequest = { filePath: string };
export type FileExistResult = CallResult & {
  FileExist: boolean;
};

export type FileBasicPropertiesRequest = { filePath: string };
export type FileBasicPropertiesResult = CallResult & {
  Size: number;
  LastModified: Date;
};

export type DownloadRequest = { filePath: string };
export type DownloadResult = CallResult & {
  ProcessId: string;
};

export type DownloadChunkRequest = {
  downloadRequestId: string;
  chunkNumber: number;
};
export type DownloadChunkResult = CallResult & {
  /**
   * A Base 64 encoded chunk of the file
   */
  FileContent: string;
};

export type DownloadCompletedRequest = { downloadRequestId: string };
export type DownloadCompletedResult = CallResult;

export type ItemCheckSumRequest = { filePath: string; hashAlgorithm: 'sha256' };
export type ItemCheckSumResult = CallResult & { CheckSum: string };

export type UploadRequestRequest = {
  fileName: string;
  filePath: string;
  checkSum: string;
  chunkNumber: number;
};

export type UploadRequestResult = CallResult & {
  UploadRequestId: string;
};

export enum UploadChunkType {
  blob = 'blob',
  byteArray = 'byteArray',
}

export type BaseUploadChunkRequest = {
  uploadRequestId: string;
  chunkNumber: number; // Zero-indexed
};

export type UploadBlobChunkRequest = BaseUploadChunkRequest & {
  blob: Blob;
  chunkType: UploadChunkType.blob;
};

export type UploadByteArrayChunkRequest = BaseUploadChunkRequest & {
  byteArray: number[];
  chunkType: UploadChunkType.byteArray;
};

export type UploadChunkRequest =
  | UploadByteArrayChunkRequest
  | UploadBlobChunkRequest;

export type UploadChunkResult = CallResult;

export type UploadCompletedRequest = {
  uploadRequestId: string;
  fileName: string;
};

export type UploadCompletedResult = CallResult;

export type GetUserItemsByShareRequest = {
  path: string;
};

export enum DocumentInfoType {
  HomeDrive = 1,
  NetworkDrive = 2,
  Directory = 3,
  File = 4,
}

export type DocumentInfo = {
  Name: string;
  Extension: string | null;
  DriveLetter: string | null;
  NetworkPath: string;
  ServerLastModified: Date;
  SizeInBytes: number;
  Type: DocumentInfoType;
};

export type GetUserItemsByShareResult = CallResult & {
  FilesTree: DocumentInfo[];
};

export enum StorageEntityOperations {
  RenameFile = 1,
  RenameFolder = 2,
  CopyFile = 3,
  MoveFile = 4,
  CreateFolder = 5,
  DeleteFile = 6,
}

export type StorageOperationsRequest = {
  storageEntityOperations: StorageEntityOperations;
  action1: string;
  action2: string;
};

export type StorageOperationsResult = CallResult & {
  ProcessId: string;
};

export type CreateSharedAccessSignatureRequest = {
  Uri: string;
  /** 1 = Read, 2 = Write */
  Permission: number;
  ExpirationInSeconds: number;
};

export type CreateSharedAccessSignatureResult = CallResult & {
  Signature: string;
  SignedUri: string;
};

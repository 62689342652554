import { StorageAccessCredentials } from './types';

export const buildSignedUrl = (
  credentials: StorageAccessCredentials,
  objectKey: string,
  additionalQueryParams = {}
): string => {
  const { signature, host } = credentials;
  const baseSignature = Object.fromEntries(
    new URLSearchParams(signature).entries()
  );

  const qs = new URLSearchParams({
    ...baseSignature,
    ...additionalQueryParams,
  }).toString();

  return `${host}/${objectKey}?${qs}`;
};

import axios, { CancelTokenSource } from 'axios';

import { assertNotUndefined } from './assertions';
import { ResumableUploadsStore } from './ResumableUploadsStore';

import {
  CollaboardObjectToRestore,
  CollaboardObjectToUpload,
  CollaboardObject,
  OnProgressCallback,
  OnResumeUploadChunk,
  OnResumeUploadComplete,
  ProjectDetails,
  RequestAborted,
  SignatureAction,
  StorageAccessCredentials,
  StorageAccessCredentialsProvider,
  StorageClientConfig,
  StorageObject,
  StorageProvider,
} from './types';

export abstract class StorageClient {
  protected provider: StorageProvider;
  protected enableResumableUploads: boolean;
  protected resumableUploadsStore: ResumableUploadsStore;

  protected cancelToken: CancelTokenSource;

  private getStorageAccessCredentialsProvider: StorageAccessCredentialsProvider;
  private credentials?: StorageAccessCredentials;
  private credentialsRequestInFlight?: Promise<void>;
  private credentialsInFlightResolver?: () => void;

  protected projectId?: string | number;
  protected containerUri?: string;

  protected onResumeUploadChunk?: OnResumeUploadChunk;
  protected onResumeUploadComplete?: OnResumeUploadComplete;

  protected downloadChunkSize: number;
  protected uploadChunkSize: number;

  protected MIN_CHUNK_SIZE = 1; // 1 byte
  protected DEFAULT_CHUNK_SIZE = 1024 * 128; // 128 Kb

  // Azure URI already includes the project ID
  protected USE_PROJECT_ID_IN_KEY = true;

  /**
   * CloudFront allows us to use a generic, wildcard signature which is valid
   * for all actions within a project's bucket.
   *
   * Direct S3 access requires separate signing of each individual action.
   */
  protected SIGN_EVERY_REQUEST = false;

  constructor(config: StorageClientConfig) {
    const {
      enableResumableUploads,
      provider,
      downloadChunkSize = this.DEFAULT_CHUNK_SIZE,
      uploadChunkSize = this.DEFAULT_CHUNK_SIZE,
      getStorageAccessCredentialsProvider,
      onResumeUploadChunk,
      onResumeUploadComplete,
    } = config;

    this.provider = provider;
    this.enableResumableUploads = enableResumableUploads;
    this.downloadChunkSize = Math.max(this.MIN_CHUNK_SIZE, downloadChunkSize);
    this.uploadChunkSize = Math.max(this.MIN_CHUNK_SIZE, uploadChunkSize);
    this.getStorageAccessCredentialsProvider = getStorageAccessCredentialsProvider;

    this.onResumeUploadChunk = onResumeUploadChunk;
    this.onResumeUploadComplete = onResumeUploadComplete;

    this.resumableUploadsStore = new ResumableUploadsStore();
    this.cancelToken = axios.CancelToken.source();
  }

  /**
   * Called when the user is on a project?
   */
  public async initStore(): Promise<void> {
    if (this.enableResumableUploads) {
      // MFT provides its own pending upload store
      if (this.provider !== StorageProvider.MFT) {
        await this.resumableUploadsStore.init();
      }
    }
  }

  public async start(): Promise<void> {
    this.cancelToken.cancel();
    this.cancelToken = axios.CancelToken.source();

    // No need to await, this will be a background task
    this.resumePendingUploads();
  }

  public async destroy(): Promise<void> {
    // Unlock queued activity before cancelling requests
    this.credentialsInFlightResolver && this.credentialsInFlightResolver();

    this.cancelToken.cancel();

    delete this.credentials;
    delete this.credentialsRequestInFlight;
    delete this.credentialsInFlightResolver;
  }

  /**
   * Set the credentials for the storage client to use.
   */
  public setProject({ projectId, containerUri }: ProjectDetails): void {
    this.projectId = projectId;
    this.containerUri = containerUri;
  }

  /**
   * Get a signed URL for accessing the file with something like an `<img>`
   */
  public abstract getUrl(object: CollaboardObject): Promise<string>;

  /**
   * Upload a file
   */
  public abstract upload(
    object: CollaboardObjectToUpload,
    file: File,
    onProgress?: OnProgressCallback
  ): Promise<void>;

  /**
   * Download an object as a Blob.
   */
  public async downloadAsBlob(
    object: CollaboardObject,
    onProgress?: OnProgressCallback
  ): Promise<Blob> {
    assertNotUndefined(this.projectId, 'projectId');

    const url = await this.getUrl(object);

    // TODO - implement multipart download
    const { data } = await axios
      .get<Blob>(url, {
        cancelToken: this.cancelToken.token,
        responseType: 'blob',
        onDownloadProgress: (event: ProgressEvent) => {
          if (onProgress) {
            onProgress(event.loaded / event.total);
          }
        },
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          throw new RequestAborted();
        }

        throw err;
      });

    return data;
  }

  /**
   * Restore a file (AKA copy previous object to a new name)
   */
  public abstract restore(
    object: CollaboardObjectToRestore,
    onProgress?: OnProgressCallback
  ): Promise<void>;

  /**
   * Resume pending uploads
   */
  protected abstract resumePendingUploads(): Promise<void>;

  /**
   * Request a signature from the API.
   */
  private async requestStorageAccessCredentials(
    projectId: string | number,
    object?: StorageObject,
    action?: SignatureAction,
    additionalParams?: { [key: string]: unknown }
  ): Promise<StorageAccessCredentials> {
    const containerUri = assertNotUndefined(this.containerUri, 'containerUri');

    const credentials = await this.getStorageAccessCredentialsProvider(
      projectId,
      object,
      action,
      additionalParams
    );

    return {
      ...credentials,
      // TODO - this will actually need to come from the API but it doesn't yet
      host: containerUri,
    };
  }

  protected async getStorageAccessCredentials(
    projectId: string | number,
    object?: StorageObject,
    action?: SignatureAction,
    additionalParams?: { [key: string]: unknown }
  ): Promise<StorageAccessCredentials> {
    if (this.SIGN_EVERY_REQUEST) {
      // In this case a new signature is required for every action
      return this.requestStorageAccessCredentials(
        projectId,
        object,
        action,
        additionalParams
      );
    }

    // If there is a signature request already inflight wait for it to complete
    // so we don't make multiple unnecessary requests
    if (this.credentialsRequestInFlight) {
      await this.credentialsRequestInFlight;
    }

    const signatureExpiry = this.credentials?.signatureExpiry || 0;
    const authTokenExpiry = this.credentials?.tokenExpiry || 0;
    const now = Date.now();

    if (this.credentials && signatureExpiry > now && authTokenExpiry > now) {
      return this.credentials;
    }

    this.credentialsRequestInFlight = new Promise(resolve => {
      this.credentialsInFlightResolver = resolve;
    });

    this.credentials = await this.requestStorageAccessCredentials(
      projectId,
      object,
      action,
      additionalParams
    );

    this.credentialsInFlightResolver && this.credentialsInFlightResolver();

    delete this.credentialsRequestInFlight;

    return this.credentials;
  }

  protected async storePendingUpload(
    object: CollaboardObjectToUpload,
    file: File
  ): Promise<void> {
    return this.resumableUploadsStore.persistFile(object, file);
  }

  protected async removePendingUpload(
    object: CollaboardObjectToUpload
  ): Promise<void> {
    return this.resumableUploadsStore.removeFile(object);
  }

  protected getStorageObjectFromObject(
    object: CollaboardObject
  ): StorageObject {
    const objectName = this.getObjectNameFromObject(object);
    return {
      objectName,
      fileName: object.fileName,
      fileType: object.fileType,
    };
  }

  /**
   * Convert object into file name used on storage.
   */
  protected getObjectNameFromObject(object: CollaboardObject): string {
    const extension = this.addDotToExtension(object.fileExtension);
    return [object.uuid, extension].join('');
  }

  protected addDotToExtension(extension: string): string {
    if (extension.startsWith('.')) {
      return extension.toLowerCase();
    }
    return `.${extension}`.toLowerCase();
  }
}

import { MftServiceClientOptions } from '../types/mftServiceClient';

export const getRequestHeaders = (
  params: Readonly<MftServiceClientOptions>
): HeadersInit => {
  const correlationHeader =
    params.correlationId === undefined
      ? undefined
      : { 'x-correlation-id': params.correlationId.toString() };

  return {
    Accept: 'application/json',
    Authorization: `Bearer ${params.userSecrets.token}`,
    'IBV-MFT-Signature': params.userSecrets.signature,

    'Content-Type': 'application/json',
    ...correlationHeader,
  };
};

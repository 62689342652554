import localforage from 'localforage';

import {
  UploadChunkRequest,
  UploadChunkType,
} from '../types/mftServiceBackend';
import { MftServiceClientOptions } from '../types/mftServiceClient';
import { defaultOptions } from './defaultOptions';

// Note: On some browsers IndexedDB is not available so we skip persisting chunks.
//       There is no alternative fallback as LocalStorage is not large enough for movies etc.
//       This is not very bad as these chunks are only a fallback for when a normal upload fails so not required in the normal flow.
let store: LocalForage | null = null;

const tmpStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'mft-service-client',
  storeName: 'pending-upload-chunks',
  description:
    'Store with the pending upload chunks. Used to restart uploads. Should be empty is there is no upload in progress.',
});

tmpStore
  .ready()
  .then(() => {
    store = tmpStore;
  })
  .catch(() => {
    console.error('localforage.indexeddb-not-available');
  });

export type RequestUploadOfChunk = {
  fileName: Readonly<string>;
  options: Readonly<MftServiceClientOptions>;
  request: Readonly<UploadChunkRequest>;
};

const getChunkKey = (chunkRequest: RequestUploadOfChunk): string =>
  `${chunkRequest.request.uploadRequestId}/${chunkRequest.request.chunkNumber}`;

const isPendingChunk = (
  chunk: RequestUploadOfChunk | null | undefined
): chunk is RequestUploadOfChunk => {
  return !!chunk;
};

export const getPendingRequestChunks = async (
  thisOptions?: Partial<Readonly<MftServiceClientOptions>>,
  options?: Partial<Readonly<MftServiceClientOptions>>
): Promise<RequestUploadOfChunk[]> => {
  const params: MftServiceClientOptions = {
    ...defaultOptions,
    ...thisOptions,
    ...options,
  };

  if (!params.useStorage || !store) {
    return [];
  }

  const keys = (await store.keys()) ?? [];

  const maybeChunks = await Promise.all(
    keys.map(key => store?.getItem<RequestUploadOfChunk>(key))
  );

  const pendingChunks = maybeChunks.filter(isPendingChunk);

  return pendingChunks.map(chunk => {
    const hasChunkType = !!chunk.request.chunkType;
    return {
      ...chunk,
      options: {
        ...chunk.options,
        ...options,
        // Ensure the byte array upload is used for legacy chunks without a chunkType
        uploadChunkAsByteArray: hasChunkType
          ? chunk.options.uploadChunkAsByteArray
          : true,
      },
      request: {
        ...chunk.request,
        // Stale chunks from storage may not have this value
        chunkType: chunk.request.chunkType ?? UploadChunkType.byteArray,
      },
    };
  }) as RequestUploadOfChunk[];
};

export const savePendingRequestChunk = async (
  chunkRequest: RequestUploadOfChunk,
  params: MftServiceClientOptions
): Promise<void> => {
  if (!params.useStorage) {
    return;
  }

  const key = getChunkKey(chunkRequest);
  const {
    // Pull out properties that should NOT be stored
    getUserSecrets,
    userSecrets,
    abortController, // AbortController is not clone-able, so can't be stored
    ...optionsWithoutUserSecrets
  } = chunkRequest.options;

  Object.keys(optionsWithoutUserSecrets)
    .filter(
      key => typeof (optionsWithoutUserSecrets as any)[key] === 'function'
    )
    .forEach(key => {
      delete (optionsWithoutUserSecrets as any)[key];
    });

  await store?.setItem(key, {
    ...chunkRequest,
    options: optionsWithoutUserSecrets,
  });
};

export const removePendingRequestChunk = async (
  chunkRequest: RequestUploadOfChunk,
  params: MftServiceClientOptions
): Promise<void> => {
  if (!params.useStorage) {
    return;
  }

  const key = getChunkKey(chunkRequest);

  await store?.removeItem(key);
};

import { BaseRequestPayload } from '../types/mftServiceBackend';
import { MftServiceClientOptions } from '../types/mftServiceClient';

export const getBaseRequest = (
  params: Readonly<MftServiceClientOptions>
): BaseRequestPayload => {
  return {
    appVer: params.appVer,
    uniqueDeviceId: params.uniqueDeviceId,
  };
};

/**
 * Polyfill for Blob.arrayBuffer()
 *
 * Required by Safari, Opera and IE: https://caniuse.com/mdn-api_blob_arraybuffer
 *
 * Based on: https://gist.github.com/hanayashiki/8dac237671343e7f0b15de617b0051bd
 */
function polyfillArrayBuffer(this: File | Blob): Promise<ArrayBuffer> {
  return new Promise(resolve => {
    let fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result as ArrayBuffer);
    };
    fr.readAsArrayBuffer(this);
  });
}

Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || polyfillArrayBuffer;

// eslint-disable-next-line no-restricted-globals
if ('File' in self) {
  File.prototype.arrayBuffer =
    File.prototype.arrayBuffer || polyfillArrayBuffer;
}

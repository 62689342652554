import { MftServiceClientOptionsValidator } from '../MftServiceClientOptionsValidator';
import { MftServiceClientOptions } from '../types/mftServiceClient';

const validator = new MftServiceClientOptionsValidator();

export const refreshUserSecretsIfExpired = async (
  params: Readonly<MftServiceClientOptions>
): Promise<Readonly<MftServiceClientOptions>> => {
  try {
    validator.validateUserSecrets(params);
    return params;
  } catch {
    const userSecrets = await params.getUserSecrets();

    const updatedParams = {
      ...params,
      userSecrets,
    };

    validator.validateUserSecrets(updatedParams);

    return updatedParams;
  }
};

import { MftServiceClientOptionsValidator } from './MftServiceClientOptionsValidator';
import { MftServiceClientOptions } from './types/mftServiceClient';
import {
  UploadBlobChunkRequest,
  UploadByteArrayChunkRequest,
  UploadChunkRequest,
  UploadChunkResult,
  UploadCompletedRequest,
  UploadCompletedResult,
  UploadRequestRequest,
  UploadRequestResult,
} from './types/mftServiceBackend';
import { defaultOptions } from './utils/defaultOptions';
import { fetchDataWithRetry } from './utils/fetchDataWithRetry';
import { getBaseRequest } from './utils/getBaseRequest';
import { getRequestHeaders } from './utils/getRequestHeaders';
import { refreshUserSecretsIfExpired } from './utils/userSecrets';

export class Upload {
  private readonly validator = new MftServiceClientOptionsValidator();

  constructor(private options: Partial<Readonly<MftServiceClientOptions>>) {}

  async uploadRequest(
    request: Readonly<UploadRequestRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<UploadRequestResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateUploadRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
      chunkSize: params.bufferSize,
    };

    const result = await fetchDataWithRetry<UploadRequestResult>(
      `${params.origin}/api/upload/v1/UploadRequest`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onUploadRequestDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  async uploadChunk(
    request: Readonly<UploadChunkRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<UploadChunkResult> {
    if (request.chunkType === 'byteArray') {
      return this._uploadChunkAsByteArray(request, options);
    }
    return this._uploadChunkAsBlob(request, options);
  }

  /**
   * Upload chunks as binary. Requires `UploadChunkRaw` endpoint.
   */
  async _uploadChunkAsBlob(
    request: Readonly<UploadBlobChunkRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<UploadChunkResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateUploadChunk(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const { blob: body, chunkType, chunkNumber, ...rest } = request;
    const queryParams = {
      ...getBaseRequest(params),
      ...rest,
      chunkNumber: String(chunkNumber), // URLSearchParams requires strings
    };

    const qs = new URLSearchParams(queryParams).toString();
    const url = `${params.origin}/api/upload/v1/UploadChunkRaw?${qs}`;

    const result = await fetchDataWithRetry<UploadChunkResult>(url, {
      method: 'POST',
      headers,
      body,
      signal: params.abortController?.signal,
    });

    try {
      params.onUploadChunkDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  /**
   * Legacy upload method required for older API versions without
   * `UploadChunkRaw` endpoint.
   */
  async _uploadChunkAsByteArray(
    request: Readonly<UploadByteArrayChunkRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<UploadChunkResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateUploadChunk(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const { byteArray, chunkType, ...rest } = request;
    const body = {
      ...getBaseRequest(params),
      ...rest,
      byteArray,
    };

    const result = await fetchDataWithRetry<UploadChunkResult>(
      `${params.origin}/api/upload/v1/UploadChunk`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onUploadChunkDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  async uploadCompleted(
    request: Readonly<UploadCompletedRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<UploadCompletedResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateUploadCompleted(request);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<UploadCompletedResult>(
      `${params.origin}/api/upload/v1/UploadCompleted`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onUploadCompletedDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }
}

import {
  MftServiceClientOptions,
  UserSecrets,
} from '../types/mftServiceClient';

const noop = () => {};

const initialUserSecrets: UserSecrets = {
  token: '',
  tokenExpiry: 0,
  signature: '',
  signatureExpiry: 0,
};

export const defaultOptions: Readonly<MftServiceClientOptions> = {
  abortController: undefined,
  appVer: '',
  bufferSize: 512_000,
  correlationId: undefined,
  maximumFileSize: 15 * 1_024 * 1_024,
  maximumChunkTransferRetry: 5,
  maximumChunkTransferTasks: 5,
  getUserSecrets: async () => ({ ...initialUserSecrets }),
  onCheckActivitiesDone: noop,
  onCheckDone: noop,
  onDownloadChunkDone: noop,
  onDownloadCompletedDone: noop,
  onDownloadRequestDone: noop,
  onFileBasicPropertiesDone: noop,
  onFileExistDone: noop,
  onGetUserItemsByShareDone: noop,
  onItemCheckSumDone: noop,
  onStorageOperationsDone: noop,
  onUploadChunkDone: noop,
  onUploadCompletedDone: noop,
  onUploadRequestDone: noop,
  onWaitForActionReady: noop,
  onWaitForChunksReadyDone: noop,
  onWaitForMergeReadyDone: noop,
  origin: '',
  pollingRetryMaximumDuration: 60_000,
  pollingSleepTime: 1_000,
  uniqueDeviceId: '',
  userSecrets: {
    ...initialUserSecrets,
  },
  useStorage: true,
  uploadChunkAsByteArray: false,
};

import type { UserSecrets } from "mft-service-client";

export {
  UserSecrets
}

export enum SignatureAction {
  GetObject = 'GetObject',
  HeadObject = 'HeadObject',
  PutObject = 'PutObject', // An atomic upload
  StartMultipartUpload = 'StartMultipartUpload',
  UploadMultipartChunk = 'UploadMultipartChunk',
  CompleteMultipartUpload = 'CompleteMultipartUpload',
}

export enum StorageProvider {
  S3 = 'S3',
  S3CloudFront = 'S3CloudFront',
  Azure = 'Azure',
  MFT = 'MFT',
}

export type ProjectDetails = {
  projectId: string | number;
  containerUri: string;
}

export type OnProgressCallback = (progress: number) => void;
export type OnResumeUploadChunk = (object: ResumedObject, progress?: number) => void;
export type OnResumeUploadComplete = (object: ResumedObject) => void;

export interface ResumedObject {
  uuid: string;
  type: string;
  fileName: string;
  projectId: string;
}


export interface CollaboardObject {
  uuid: string;
  fileName: string;
  fileExtension: string;
  fileType: string;
}

export interface CollaboardObjectToUpload extends CollaboardObject {
  type: string;
}

export interface CollaboardObjectToRestore extends CollaboardObject {
  previousUuid: string;
}

export interface StorageObject {
  objectName: string; // {uuid}.{extension}
  fileName: string; // Original file name
  fileType: string; // Mime-type
}

export enum ErrorMessages {
  INVALID_PROVIDER = 'Invalid provider',
  MISSING_PROVIDER = 'Missing provider',
  NO_ACCESS_CREDENTIALS_PROVIDER = 'Missing required getStorageAccessCredentials',
  NO_SIGNATURE = "No signature",
  NO_RESUMABLE_UPLOADS_STORE = "Browser does not support resumable uploads store",
  UPLOAD_INCOMPLETE = "Unable to operate on incomplete upload",
  FILE_NOT_FOUND = "File not found",
}

export class RequestAborted extends Error {}

export interface FileChunk {
  chunk: Blob;
  chunkNumber: number;
}

export interface UploadedChunk {
  chunkId: string;
  chunkNumber: number;
}

export type StorageAccessCredentials = UserSecrets & {
  host?: string; // Required for AWS
}

export type StorageAccessCredentialsProvider = (
  projectId: string | number,
  object?: StorageObject,
  action?: SignatureAction,
  additionalParams?: { [key: string]: unknown }
) => Promise<StorageAccessCredentials>;

export type StorageClientConfig = {
  provider: StorageProvider;
  enableResumableUploads: boolean; // Ignored by MFT
  downloadChunkSize?: number;
  uploadChunkSize?: number;
  // TODO - For S3 we will need to included object uuid in this request
  getStorageAccessCredentialsProvider: StorageAccessCredentialsProvider;
  mft: {
    // TODO - remove these or make generic?
    appVer: string;
    deviceId: string;
    maximumFileSize?: number;
    origin: string;
    pollingRetryMaximumDuration?: number;
    uploadChunkAsByteArray?: boolean;
  },
  onResumeUploadChunk?: OnResumeUploadChunk,
  onResumeUploadComplete?: OnResumeUploadComplete
};

import { MftServiceClientOptionsValidator } from './MftServiceClientOptionsValidator';
import { fetchDataWithRetry } from './utils/fetchDataWithRetry';
import { getRequestHeaders } from './utils/getRequestHeaders';
import { getBaseRequest } from './utils/getBaseRequest';
import { defaultOptions } from './utils/defaultOptions';
import { refreshUserSecretsIfExpired } from './utils/userSecrets';
import { MftServiceClientOptions } from './types/mftServiceClient';
import { ActivityResult } from './types/mftServiceBackend';

export class Polling {
  private readonly validator = new MftServiceClientOptionsValidator();

  constructor(private options: Partial<Readonly<MftServiceClientOptions>>) {}

  /**
   * Check the server for activities for the current user/device.
   *
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  public async checkActivities(
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<ActivityResult> {
    const initialParams: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);

    const params = await refreshUserSecretsIfExpired(initialParams);
    const headers = getRequestHeaders(params);
    const body = getBaseRequest(params);

    const result = await fetchDataWithRetry<ActivityResult>(
      `${params.origin}/api/polling/v1/checkactivities`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onCheckActivitiesDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }
}

import { MftServiceClientOptionsValidator } from './MftServiceClientOptionsValidator';
import { MftServiceClientOptions } from './types/mftServiceClient';
import {
  CheckRequest,
  CheckResult,
  FileBasicPropertiesRequest,
  FileBasicPropertiesResult,
  FileExistRequest,
  FileExistResult,
} from './types/mftServiceBackend';
import { getRequestHeaders } from './utils/getRequestHeaders';
import { getBaseRequest } from './utils/getBaseRequest';
import { fetchDataWithRetry } from './utils/fetchDataWithRetry';
import { defaultOptions } from './utils/defaultOptions';
import { refreshUserSecretsIfExpired } from './utils/userSecrets';

export class CheckFile {
  private readonly validator = new MftServiceClientOptionsValidator();

  constructor(private options: Partial<Readonly<MftServiceClientOptions>>) {}

  /**
   *
   * @param request The `filesPath` to check.
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async check(
    request: Readonly<CheckRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<CheckResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateCheckRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);

    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<CheckResult>(
      `${params.origin}/api/checkfile/v1/Check`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    Object.keys(result.FilesInfo).forEach(key => {
      // Convert JSON string result to Date type
      result.FilesInfo[key] = new Date(result.FilesInfo[key]);
    });

    try {
      params.onCheckDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  /**
   *
   * @param request The `filePath` to check for existence
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async fileExist(
    request: Readonly<FileExistRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<FileExistResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateFileExistRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);

    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<FileExistResult>(
      `${params.origin}/api/checkfile/v1/FileExist`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    try {
      params.onFileExistDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }

  /**
   *
   * @param request The `filePath` to check for properties
   * @param options The parameters used for the AJAX request. These are merged with the defaults passed in the constructor.
   */
  async fileBasicProperties(
    request: Readonly<FileBasicPropertiesRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<FileBasicPropertiesResult> {
    const initialParams: Readonly<MftServiceClientOptions> = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    this.validator.validate(initialParams);
    this.validator.validateFileBasicPropertiesRequest(request);

    const params = await refreshUserSecretsIfExpired(initialParams);

    const headers = getRequestHeaders(params);
    const body = {
      ...getBaseRequest(params),
      ...request,
    };

    const result = await fetchDataWithRetry<FileBasicPropertiesResult>(
      `${params.origin}/api/checkfile/v1/FileBasicProperties`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
        signal: params.abortController?.signal,
      }
    );

    result.LastModified = new Date(result.LastModified);

    try {
      params.onFileBasicPropertiesDone(body, result, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return result;
  }
}

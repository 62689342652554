import { saveAs } from 'file-saver';
import {
  ActivityModel,
  ActivityResult,
  StorageEntityOperations,
  UploadChunkType,
  UploadBlobChunkRequest,
  UploadByteArrayChunkRequest,
  BaseUploadChunkRequest,
} from './types/mftServiceBackend';
import { MftServiceClientOptions } from './types/mftServiceClient';

import './polyfills/arrayBuffer';
import { decryptFile } from './utils/encryption';
import { defaultOptions } from './utils/defaultOptions';
import { sha256 } from './utils/hash';
import { sleep } from './utils/sleep';
import {
  getPendingRequestChunks,
  RequestUploadOfChunk,
  savePendingRequestChunk,
  removePendingRequestChunk,
} from './utils/storage';

import { CheckFile } from './CheckFile';
import { Download } from './Download';
import { Polling } from './Polling';
import { StorageOperations } from './StorageOperations';
import { Upload } from './Upload';
import { Users } from './Users';

type CreateSharedAccessSignatureRequest = {
  /*** The full path of the file name to be able to get */
  fileName: string;
  /*** The expiration of the secure URL in seconds. Defaults to 8 hours */
  expirationInSeconds?: number;
};

export class MftServiceClient {
  public readonly checkFile: CheckFile;
  public readonly download: Download;
  public readonly polling: Polling;
  public readonly storageOperations: StorageOperations;
  public readonly upload: Upload;
  public readonly users: Users;

  private _activeTaskCount = 0;
  private _pendingTasks: (() => Promise<void>)[] = [];

  /**
   *
   * @param options The default parameters used for the AJAX request. These are merged with the parameters passed to the functions.
   */
  constructor(
    private readonly options: Partial<Readonly<MftServiceClientOptions>>
  ) {
    this.checkFile = new CheckFile(options);
    this.download = new Download(options);
    this.polling = new Polling(options);
    this.storageOperations = new StorageOperations(options);
    this.upload = new Upload(options);
    this.users = new Users(options);
  }

  /**
   *
   * @param filePath The source file to download.
   * @param options The default parameters used for the AJAX request. These are merged with the parameters passed to the functions.
   */
  public async downloadToBlob(
    filePath: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<Blob> {
    const fileChunks = await this._downloadFileChunks(filePath, options);

    const byteChunks = fileChunks.map(c => {
      return new Uint8Array(
        Array.from(c.split('').map((_, i) => c.charCodeAt(i)))
      );
    });

    return new Blob(byteChunks);
  }

  /**
   *
   * @param filePath The source file to download.
   * @param target The target file name.
   * @param options The default parameters used for the AJAX request. These are merged with the parameters passed to the functions.
   */
  public async downloadToFile(
    filePath: string,
    target: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    const blob = await this.downloadToBlob(filePath, options);

    saveAs(blob, target);
  }

  /**
   *
   * @param filePath The file to download.
   * @param type The mime type to use for the data URL returned.
   * @param options The default parameters used for the AJAX request. These are merged with the parameters passed to the functions.
   */
  public async downloadToDataURL(
    filePath: string,
    type: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<string> {
    const fileChunks = await this._downloadFileChunks(filePath, options);

    const itemCheckSum = await this.download.itemCheckSum(
      {
        filePath,
        hashAlgorithm: 'sha256',
      },
      options
    );

    const completeFileContent = decryptFile(fileChunks.join(''));

    if (itemCheckSum.CheckSum !== (await sha256(completeFileContent))) {
      throw new Error('Mismatch in SHA256 CheckSum');
    }

    return `data:${type};base64,${btoa(completeFileContent)}`;
  }

  private async _downloadFileChunks(
    filePath: string,
    options: Partial<Readonly<MftServiceClientOptions>> | undefined
  ) {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    const fileProperties = await this.checkFile.fileBasicProperties(
      {
        filePath: filePath,
      },
      options
    );

    if (fileProperties.Size > params.maximumFileSize) {
      throw new Error(
        `Exceeding the maximum file size of ${params.maximumFileSize} bytes`
      );
    }

    const downloadRequest = await this.download.downloadRequest(
      { filePath },
      options
    );

    const numberOfChunks = await this._waitForChunksReady(
      downloadRequest.ProcessId,
      options
    );

    const fileChunks = await this._downloadAllChunks(
      downloadRequest.ProcessId,
      numberOfChunks,
      options
    );

    await this.download.downloadCompleted(
      {
        downloadRequestId: downloadRequest.ProcessId,
      },
      options
    );

    return fileChunks;
  }

  private async _downloadAllChunks(
    processId: string,
    numberOfChunks: number,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<string[]> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    const pendingChunks = new Array(numberOfChunks)
      .fill(null)
      .map((_, chunkNumber) => {
        let retryCount = 0;
        return new Promise<string>((resolve, reject) => {
          // Queue a task to transfer this chunk
          this._pendingTasks.push(() => {
            retryCount++;

            if (retryCount > params.maximumChunkTransferRetry) {
              // Reject for the transfer result
              reject(
                new Error('Maximum transfer retry for downloadChunk() exceeded')
              );
              // Resolve the task as we exceeded the maximumChunkTransferRetry
              return Promise.resolve();
            }

            return (
              this.download
                .downloadChunk(
                  { downloadRequestId: processId, chunkNumber },
                  options
                )
                .then(chunk => chunk.FileContent)
                // Resolve for the transfer result
                .then(fileContent => resolve(fileContent))
            );
          });
        });
      });

    this._executePendingTasks(params);

    const fileChunks = Promise.all(pendingChunks);

    return fileChunks;
  }

  private _executePendingTasks(
    params: Readonly<MftServiceClientOptions>
  ): void {
    const maximumChunkTransferTasks = params.maximumChunkTransferTasks;

    const startTask = () => {
      if (this._activeTaskCount < maximumChunkTransferTasks) {
        const task = this._pendingTasks.shift();
        if (task) {
          this._activeTaskCount++;

          task()
            .catch(err => {
              // Do not retry when the request has been aborted by the user
              if (err?.name !== 'AbortError') {
                console.warn(err);
                // Download chunk task failed less than retry count. Queue again for retry
                this._pendingTasks.push(task);
              }
            })
            .finally(() => {
              this._activeTaskCount--;
              startTask();
            });

          if (this._activeTaskCount < maximumChunkTransferTasks) {
            startTask();
          }
        }
      }
    };

    startTask();
  }

  public async resumePendingUploads(
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    const pendingChunks = await getPendingRequestChunks(this.options, options);

    const pendingChunksTasks = pendingChunks.map(chunk =>
      this._requestUploadOfChunk(chunk, params)
    );

    this._executePendingTasks(params);

    const fileNames = new Map(
      pendingChunks.map(chunk => [
        chunk.request.uploadRequestId,
        chunk.fileName,
      ])
    );
    const correlationIds = new Map(
      pendingChunks.map(chunk => [
        chunk.request.uploadRequestId,
        chunk.options.correlationId,
      ])
    );

    await Promise.all(pendingChunksTasks);

    const completedAndMerged = Array.from(fileNames.keys()).map(
      async uploadRequestId => {
        await this.upload.uploadCompleted(
          {
            uploadRequestId,
            fileName: fileNames.get(uploadRequestId) ?? 'Unknown',
          },
          { ...options, correlationId: correlationIds.get(uploadRequestId) }
        );

        await this._waitForMergeReady(uploadRequestId, params);
      }
    );

    await Promise.all(completedAndMerged);
  }

  /**
   *
   * @param file The File object to upload.
   * @param filePath The path for the file.
   * @param options The default parameters used for the AJAX request. These are merged with the parameters passed to the functions.
   */
  public async uploadFile(
    file: File,
    filePath: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    if (file.size > params.maximumFileSize) {
      throw new Error(
        `Exceeding the maximum file size of ${params.maximumFileSize} bytes`
      );
    }

    const fileContentAsArrayBuffer = await file.arrayBuffer();
    const checkSum = await sha256(fileContentAsArrayBuffer);
    const numberOfChunks = Math.ceil(file.size / params.bufferSize);

    const uploadRequest = await this.upload.uploadRequest(
      {
        fileName: file.name,
        filePath,
        checkSum,
        chunkNumber: numberOfChunks,
      },
      options
    );
    const uploadRequestId = uploadRequest.UploadRequestId;

    await this._uploadAllChunks(
      uploadRequestId,
      file,
      fileContentAsArrayBuffer,
      numberOfChunks,
      params
    );

    await this.upload.uploadCompleted(
      {
        uploadRequestId,
        fileName: file.name,
      },
      options
    );

    await this._waitForMergeReady(uploadRequest.UploadRequestId, params);
  }

  private async _uploadAllChunks(
    uploadRequestId: string,
    file: File,
    fileContentAsArrayBuffer: ArrayBuffer,
    numberOfChunks: number,
    options: Readonly<MftServiceClientOptions>
  ) {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    const uploadChunkRequests = this._createUploadChunkRequests(
      uploadRequestId,
      file,
      fileContentAsArrayBuffer,
      numberOfChunks,
      params
    );

    const pendingSaves = uploadChunkRequests.map(chunk =>
      savePendingRequestChunk(chunk, params)
    );
    await Promise.all(pendingSaves);

    const pendingChunks = uploadChunkRequests.map(chunkRequest =>
      this._requestUploadOfChunk(chunkRequest, params)
    );

    this._executePendingTasks(params);

    await Promise.all(pendingChunks);
  }

  private _createUploadChunkRequests = (
    uploadRequestId: string,
    file: File,
    arrayBuffer: ArrayBuffer,
    numberOfChunks: number,
    params: Readonly<MftServiceClientOptions>
  ): RequestUploadOfChunk[] => {
    const { uploadChunkAsByteArray } = params;
    return new Array(numberOfChunks).fill(null).map((_, chunkNumber) => {
      const start = chunkNumber * params.bufferSize;
      const end = (chunkNumber + 1) * params.bufferSize;

      const baseRequest: BaseUploadChunkRequest = {
        uploadRequestId,
        chunkNumber,
      };

      const request = uploadChunkAsByteArray
        ? this._createUploadByteArrayChunk(baseRequest, arrayBuffer, start, end)
        : this._createUploadBlobChunk(baseRequest, file, start, end);

      const value: RequestUploadOfChunk = {
        request,
        options: params,
        fileName: file.name,
      };

      return value;
    });
  };

  private _createUploadBlobChunk = (
    baseRequest: BaseUploadChunkRequest,
    file: File,
    start: number,
    end: number
  ): UploadBlobChunkRequest => {
    return {
      ...baseRequest,
      blob: file.slice(start, end),
      chunkType: UploadChunkType.blob,
    };
  };

  private _createUploadByteArrayChunk = (
    baseRequest: BaseUploadChunkRequest,
    arrayBuffer: ArrayBuffer,
    start: number,
    end: number
  ): UploadByteArrayChunkRequest => {
    return {
      ...baseRequest,
      byteArray: Array.from(new Uint8Array(arrayBuffer.slice(start, end))),
      chunkType: UploadChunkType.byteArray,
    };
  };

  private async _requestUploadOfChunk(
    chunkRequest: RequestUploadOfChunk,
    params: MftServiceClientOptions
  ) {
    let retryCount = 0;
    return new Promise<string>((resolve, reject) => {
      // Queue a task to transfer this chunk
      this._pendingTasks.push(() => {
        retryCount++;

        if (retryCount > chunkRequest.options.maximumChunkTransferRetry) {
          // Reject for the transfer result
          reject(
            new Error('Maximum transfer retry for uploadChunk() exceeded')
          );
          // Resolve the task as we exceeded the maximumChunkTransferRetry
          return Promise.resolve();
        }

        return (
          this.upload
            .uploadChunk(chunkRequest.request, chunkRequest.options)
            .then(() => removePendingRequestChunk(chunkRequest, params))
            // Resolve for the transfer result
            .then(() => resolve())
        );
      });
    });
  }

  private async _waitForChunksReady(
    processId: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<number> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    const process = await this._waitForAction(
      a => a.ChunkedOnServer.find(e => e.Id === processId),
      options
    );

    try {
      params.onWaitForChunksReadyDone(
        { processId },
        process,
        params.correlationId
      );
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }

    return process.ChunkSize; // The number of chunks
  }

  private async _waitForMergeReady(
    processId: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    await this._waitForAction(
      a => a.MergedOnServer.find(e => e.Id === processId),
      options
    );

    try {
      params.onWaitForMergeReadyDone({ processId }, {}, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }
  }

  public async renameFile(
    source: string,
    target: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    await this.storageOperation(
      StorageEntityOperations.RenameFile,
      source,
      target,
      options
    );
  }

  public async renameFolder(
    source: string,
    target: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    await this.storageOperation(
      StorageEntityOperations.RenameFolder,
      source,
      target,
      options
    );
  }

  public async copyFile(
    source: string,
    target: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    await this.storageOperation(
      StorageEntityOperations.CopyFile,
      source,
      target,
      options
    );
  }

  public async moveFile(
    source: string,
    target: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    await this.storageOperation(
      StorageEntityOperations.MoveFile,
      source,
      target,
      options
    );
  }

  public async createFolder(
    folderName: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    await this.storageOperation(
      StorageEntityOperations.CreateFolder,
      folderName,
      '',
      options
    );
  }

  public async deleteFile(
    fileName: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    await this.storageOperation(
      StorageEntityOperations.DeleteFile,
      fileName,
      '',
      options
    );
  }

  public async storageOperation(
    storageEntityOperations: StorageEntityOperations,
    action1: string,
    action2: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ) {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    const operation = await this.storageOperations.storageOperations(
      {
        storageEntityOperations,
        action1,
        action2,
      },
      params
    );

    await this._waitForActionReady(operation.ProcessId, params);
  }

  public async getResourceURL(
    request: Readonly<CreateSharedAccessSignatureRequest>,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<string> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    return `${
      params.origin
    }/api/Download/v1/StreamFile?fileAndPath=${encodeURIComponent(
      request.fileName
    )}&sig=${encodeURIComponent(params.userSecrets.signature)}`;
  }

  private async _waitForActionReady(
    processId: string,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<void> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };

    await this._waitForAction(
      a => a.CompletedOperationsOnServer.find(e => e.Id === processId),
      params
    );

    try {
      params.onWaitForActionReady({ processId }, {}, params.correlationId);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }
  }

  private async _waitForAction(
    findProcess: (activities: ActivityResult) => ActivityModel | undefined,
    options?: Partial<Readonly<MftServiceClientOptions>>
  ): Promise<ActivityModel> {
    const params: MftServiceClientOptions = {
      ...defaultOptions,
      ...this.options,
      ...options,
    };
    const maxTime = Date.now() + params.pollingRetryMaximumDuration;

    while (true) {
      const activities = await this.polling.checkActivities(options);
      const process = findProcess(activities);

      if (process) {
        return process;
      }

      if (Date.now() > maxTime) {
        // Maximum duration passed and no requested process not found yet
        throw new Error(
          `File not chunked or merged on server after ${params.pollingRetryMaximumDuration} ms`
        );
      }

      await sleep(params.pollingSleepTime);
    }
  }
}

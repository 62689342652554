import polly from 'polly-js';

import { isJSON } from './isJSON';
import { ServerError } from './ServerError';

/**
 *
 * @param url The address to send the request to
 * @param init Options for the `fetch` request
 *
 * In general, prefer using `fetchDataWithRetry()` over this function.
 */
export const fetchDataWithoutRetry = async <T>(
  url: string,
  init: Readonly<RequestInit> = {}
) => {
  const rsp = await fetch(url, init);

  if (!rsp.ok) {
    const err: any = new Error(rsp.statusText);
    err.status = rsp.status;
    throw err;
  }

  const contentTypeHeader = rsp.headers.get('content-type');

  if (isJSON(contentTypeHeader)) {
    const json = await rsp.json();

    if (json.ErrorCode) {
      throw new ServerError(json.ErrorCode);
    }

    return json as T;
  }

  throw new Error('No JSON data found.');
};

/**
 *
 * @param url The address to send the request to
 * @param init Options for the `fetch` request
 *
 * Retries the AJAX request with 100, 200, 400, 800 or 1600 milliseconds delay if needed
 */
export const fetchDataWithRetry = async <T>(
  url: string,
  init: Readonly<RequestInit> = {}
): Promise<T> => {
  // Retry with 100, 200, 400, 800, 1600 milliseconds delay if needed
  const result = await polly()
    // Prevent polly from retrying when request is aborted
    .handle(err => err?.name !== 'AbortError')
    .waitAndRetry(5)
    .executeForPromise(() => fetchDataWithoutRetry<T>(url, init));

  return result;
};

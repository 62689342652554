import mime from 'mime';
import { FileChunk } from './types';

/**
 * Attempt to read file extension from the file name.
 */
export const getFileExtension = (file: File): string | undefined => {
  const { name = '', type } = file;
  let extension = name.split('.').pop();

  if (!extension && type) {
    extension = mime.getExtension(type) || undefined;
  }

  return extension;
};

/**
 * Attempt to determine file type from the file.
 */
export const getFileType = (file: File): string | undefined => {
  const { name, type } = file;

  if (type) {
    return type;
  }

  return mime.getType(name) || undefined;
};

export const chunkFile = (file: File, chunkSize: number): FileChunk[] => {
  const totalChunks = Math.ceil(file.size / chunkSize);

  return new Array(totalChunks).fill(null).map((_, index) => {
    const start = index * chunkSize;
    const end = (index + 1) * chunkSize;
    const chunk = file.slice(start, end);
    return {
      chunk,
      chunkNumber: index + 1,
    };
  });
};
